import React, {Component} from 'react'
import { connect } from 'react-redux';
import {addResourceGroup} from '../../../redux/_actions/resources/category/index'
import { getResourcesCategories, getResourcesGroups } from 'redux/_actions/resources/category/index.js';

// React Notification
import { NotificationManager } from 'react-notifications';

// reactstrap components
import {
    FormGroup,
    Card,
    CardHeader,
    Form,
    Label,
    Input,
    Button,
    Container,
    Row,
    Col
  } from "reactstrap";
  // core components
  import Header from "components/Headers/Header.js";

  export class AddGroupResource extends Component {
    state = {
      title: '',
      description: '',
      errors: {},
      name: '',
      category_id: ''
    };

    componentDidMount() {
      this.props.getResourcesCategories();
    }


    onChangeInput = (e) => this.setState({
      [e.target.name] : e.target.value
    })
  
    handleSubmit = (e) =>{
      e.preventDefault();
      
      const { title, description, name, category_id } = this.state;
  
      //Check for errors
      if(title === ''){
        this.setState({ errors: { title: 'title is required'}});
        return;
      }
      if (description === '') {
        this.setState({ errors: { description: 'description is required'} });
      }
      if (name === '') {
        this.setState({ errors: { description: 'name is required'} });
      }
      const newPropertySponsor = {
        title,
        description,
        name,
        favicon_icon: '',
        category_id
      }
     
      
      //Submit Category
      this.props.addResourceGroup(newPropertySponsor)
      NotificationManager.success('Resource category added!', 'Successful!', 2000);
      
       //Clear state
       this.setState({
        title: '',
        description: '',
        errors: {}, 
        name
      })
      this.props.history.push('/admin/resource/groups');
      
    }

    render() {
      const { title, description, errors, name, categories } = this.state;
      const catOptions = this.props.categories?.map((cat, i) => <option key={`opt-${i}`} value={cat._id}>{cat.title}</option>);

      console.log(catOptions)

    return (
        <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Create Resource Group</h3>
                </CardHeader>
                <Form onSubmit={this.handleSubmit} >
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="Title">Title</Label>
                      <Input type="text" value={title} onChange = {this.onChangeInput} name="title" error={errors.title} id="Title" />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="category">Category</Label>

                        <Input onChange = {this.onChangeInput} type="select" 
                            required
                            className="mr-sm-2"
                            name="category_id"
                          >
                            <option value=""></option>
                            {catOptions}
                        </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="Name">Name</Label>
                      <Input type="text" value={name} onChange = {this.onChangeInput} name="name" error={errors.name} id="Name" />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="description">description</Label>
                      <Input type="text" value={description} onChange={this.onChangeInput} error={errors.description} name="description" id="description" />
                    </Col>
                  </FormGroup>
                  
                  <Button onClick={this.handleSubmit} className="btn btn-primary mr-2">Submit</Button>
                </Form>
              </Card>
            </div>
          </Row>
         
        </Container>
      </>
        )
      }
    }

    const mapStateToProps = state =>  ({ 
      categories: state?.rescategories?.rescategories
    })
 export default connect(mapStateToProps, {addResourceGroup, getResourcesCategories})(AddGroupResource);

