import axiosInstance from '../../redux/axiosInstance';

class FaqService {

    

    deleteFaq(faqId){
        return axiosInstance.delete(`/faqs/${faqId}`);
    }
}

export default new FaqService()
