import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom';
import {
    FormGroup,
    Card,
    CardHeader,
    Form,
    Label,
    Input,
    Button,
    Col
  } from "reactstrap"; 
  // core components 
  import { useDispatch } from 'react-redux';
  import { addAgent } from 'redux/_actions/agents/index.js';
import axiosInstance from 'redux/axiosInstance';
import AutoComplete from 'components/AutoSuggestions/AutoSuggestion';


  const AddAgent = props => {
  const [roles, setRoles] = useState([])
  const [agents, setAgents] = useState([])
  const [selectedAgent, setselectedAgent] = useState(null)
  const dispatch = useDispatch();

  useEffect(() => {
    fetchRoles();
  }, [])
  
  const fetchRoles = async () => {
    const data = await axiosInstance.get('/enum/role/all/roles');
    setRoles(data.data.data)
  }

  const handleSearchAgent = async(value) => {
    try {
      const data = await axiosInstance.get(`/agent/agents/search?searchByName=${value}`);
      setAgents(data.data.data)
    } catch (error) {}
  }


  /* Submit New Article */
  const handleSubmit = e =>  {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      const formData = new FormData(form);
      let object = {};
      formData.forEach(function(value, key){
          object[key] = value;
      });
      object["agent_id"] = selectedAgent
      let json = JSON.stringify(object);
      dispatch(addAgent(json));
      <Redirect to="/admin/agents/" />
    }
  }
    return (
        <>
     
        {/* Page content */}

            <div className="col">
              <Card className="">
              <CardHeader className="border-0">
                <h3 className="mb-0">Create New Agent</h3>
                </CardHeader>
                <Form  onSubmit={handleSubmit} >
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="first_name">First Name</Label>
                      <Input type="text" name="first_name" required  id="first_name" />
                    </Col>
                    <Col md={6}>
                      <Label for="last_name">Last Name</Label>
                      <Input type="text" name="last_name" required  id="last_name" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="facebook">Facebook</Label>
                      <Input type="text" name="facebook"   id="facebook" />
                    </Col>
                    <Col md={6}>
                      <Label for="twitter">Twitter</Label>
                      <Input type="text" name="twitter"   id="twitter" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="instagram">Instagram</Label>
                      <Input type="text" name="instagram"   id="instagram" />
                    </Col>
                    <Col md={6}>
                      <Label for="website">Website</Label>
                      <Input type="text" name="website"   id="website" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="address1">Address 1</Label>
                      <Input type="text" name="address1" required  id="address1" />
                    </Col>
                    <Col md={6}>
                      <Label for="address2">Address 2</Label>
                      <Input type="text" name="address2" required  id="address2" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="address3">Address 3</Label>
                      <Input type="text" name="address3"   id="address3" />
                    </Col>
                    <Col md={6}>
                      <Label for="phone">Phone</Label>
                      <Input type="text" name="phone" required  id="phone" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="state">State</Label>
                      <Input type="text" name="state" required  id="state" />
                    </Col>
                    <Col md={6}>
                      <Label for="city">City</Label>
                      <Input type="text" name="city" required  id="city" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="zipcode">Zipcode</Label>
                      <Input type="text" name="zipcode" required  id="zipcode" />
                    </Col>
                    <Col md={6}>
                      <Label for="country">Country</Label>
                      <Input type="text" name="country" required  id="country" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="bio">Bio</Label>
                      <Input type="text" name="bio"   id="bio" />
                    </Col>
                    <Col md={6}>
                      <Label for="fax">Fax</Label>
                      <Input type="text" name="fax"   id="fax" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="industry">Industry</Label>
                      <Input type="text" name="industry"   id="industry" />
                    </Col>
                    <Col md={6}>
                      <Label for="education">Education</Label>
                      <Input type="text" name="education"   id="education" />
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col className="row">

                    <Col md={6}>
                      <Label for="agent_id">Agent</Label>
                      <AutoComplete placeholder="Search agent name here" list={agents} getSuggestions={handleSearchAgent} handleSelectAgent={(e) =>  setselectedAgent(e)}/>
                    </Col>

                    <Col md={6}>
                      <Label for="Role">Role</Label> <br/>
                      <select type="text" name="role" required  id="Role" >
                        <option>Select Roles</option>
                        {
                          !!roles.length && roles.map((obj, index) => {
                            return (<option value={obj._id} key={index}>{obj.name}</option>)
                          })
                        }
                      </select>
                    </Col>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={12}>
                  <Button type="submit" className="btn btn-primary mr-2">Submit</Button>
                  </Col>
                    </FormGroup>
                </Form>
              </Card>
            </div>
    
         
    
      </>
        )
    }
 export default AddAgent;

