import axiosInstance from '../../redux/axiosInstance/';

class ClaimService {

  rejectClaim(userId){
    return axiosInstance.patch(`/claim/claim-listing/admin/rejected/${userId}`, {
      "status": "rejected"
  });
  }

  approveClaim(userId){
    return axiosInstance.patch(`/claim/claim-listing/admin/approved/${userId}`, { "status": "approved"})
  }
}

export default new ClaimService()
