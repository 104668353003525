import axiosInstance from '../../redux/axiosInstance/';

class AgentService {

    deleteAgent(agentId){
        return axiosInstance.delete(`/agents/delete-agent/${agentId}`);
    }

    deactivateStatus(agentId){
        return axiosInstance.patch(`/claim/claim-listing/admin/rejected/${agentId}`, {status: "rejected"});
    }

    activateStatus(agentId){
      return axiosInstance.patch(`/claim/claim-listing/admin/approved/${agentId}`, {status: "approved"});
    }

    getAllAgents() {
        return axiosInstance.get('/agent/admin/all');
    }

  getAllClaimedAgents() {
    return axiosInstance.get('/agent/admin/claimed/all');
  }
}

export default new AgentService()
