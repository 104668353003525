import React from "react";
import Autosuggest from "react-autosuggest";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input,
} from "reactstrap";
import "./auto-suggest.css";
const getSuggestions = (value, list) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : list.filter(
        lang => lang.first_name.toLowerCase().slice(0, inputLength) === inputValue || lang.last_name.toLowerCase().slice(0, inputLength) === inputValue
      );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name || `${suggestion.first_name} ${suggestion.last_name} `;

export default class AutoComplete extends React.Component {
  constructor(props) {
    super(props);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      suggestions: props.list,
      dropdownOpen: false
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.props.getSuggestions(value)
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value } = this.state;
    const suggestions = this.props.list;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="suggest-with-trap"
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={suggestion => (
            <DropdownItem key={suggestion.value} onClick={() => this.props.handleSelectAgent(suggestion._id)}>
              {suggestion.first_name } {suggestion.last_name}
            </DropdownItem>
          )}
          renderSuggestionsContainer={({ containerProps, children, query }) => (
            <div {...containerProps}>
              <DropdownMenu>{children}</DropdownMenu>
            </div>
          )}
          renderInputComponent={inputProps => (
            <DropdownToggle tag="div">
              <InputGroup>
                <Input {...inputProps} />
              </InputGroup>
            </DropdownToggle>
          )}
          inputProps={inputProps}
        />
      </Dropdown>
    );
  }
}
