import React, { Component } from 'react';
import Modal from './Modal';
// React Notification
import { NotificationManager } from 'react-notifications';
import axiosInstance from '../../redux/axiosInstance';
import Pagination from 'react-js-pagination';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  FormGroup,
  Row
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import ClaimService from './ClaimService';
import moment from 'moment';
export default class Claims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      error: null,
      listings: [],
      listingData: null,
      selectedAgentDetail: null,
      response: {},
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 3,
      search: ''
    };
    this.rejectClaim = this.rejectClaim.bind(this);
    this.approveClaim = this.approveClaim.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  rejectClaim(id) {
    ClaimService.rejectClaim(id)
      .then((res) => {
        this.setState({
          listings: this.state.listings.filter((list) => list.id !== id)
        });
        NotificationManager.success(
          'Claim rejected successfully !',
          'Success!',
          2000
        );
        window.setTimeout(function () {
          window.location.reload();
        }, 700);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error.message ?? 'An Error occured.',
          'Error!',
          2000
        );
      });
  }

  approveClaim(id) {
    ClaimService.approveClaim(id)
      .then((res) => {
        this.setState({
          listings: this.state.listings.filter((list) => list.slug !== id)
        });
        NotificationManager.success(
          'Claim approved successfully !',
          'Success!',
          2000
        );
        // window.setTimeout(function () {
        //   window.location.reload();
        // }, 700);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error.message ?? 'An Error occured.',
          'Error!',
          2000
        );
      });
  }

  toggleModal(id) {
    axiosInstance.get(`/claim-listing/${id}`).then((response) => {
      axiosInstance.get(`/agents/${response.data.data.agent_id}`).then( res => {
        this.setState({
          listingData: response.data.data,
          selectedAgentDetail: res.data.data.data
        });
      })
    });
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentDidMount() {
    axiosInstance.get('/claim/claim-listing/admin/all').then((response) => {
      this.setState({
        listings: response.data.data.data,
        itemsCountPerPage: response.data.data.perPage,
        totalItemsCount: response.data.data.totalData,
        activePage: response.data.data.currentPage
      });
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    var query = `/claim/claim-listing/admin/all?page=${pageNumber}`

    axiosInstance.get(query).then((response) => {
      this.setState({
        listings: response.data.data.data,
        itemsCountPerPage:  response.data.data.perPage,
        totalItemsCount: response.data.data.totalData,
        activePage: response.data.data.currentPage
      });
    });
  }

  handleSearchChange(e) {
    var search = e.target.value;
    this.setState({
      search: search
    });
    var query =
      search === '' ? `/claim-listing` : `/claim-listing/?search=${search}`;

    axiosInstance.get(query).then((response) => {
      this.setState({
        listings: response.data.data.data,
        itemsCountPerPage: response.data.perPage,
        totalItemsCount: response.data.totalData,
        activePage: response.data.currentPage
      });
    });
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    List of account claims
                    <FormGroup style={{ float: 'right' }}>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => this.handleSearchChange(e)}
                        placeholder="Search here"
                      />
                    </FormGroup>
                  </h3>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Agents First Name</th>
                      <th scope="col">Agents Last Name</th>
                      <th scope="col">City</th>
                      <th scope="col">State</th>
                      <th scope="col">Zipcode</th>
                      <th scope="col">Date of Claim</th>
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.listings?.map((listing) => {
                      return listing ? (
                        <tr key={listing._id}>
                          <td>{listing?.first_name}</td>
                          <td>{listing?.last_name}</td>
                          <td>{listing?.agent_id?.city}</td>
                          <td>{listing?.agent_id?.state}</td>
                          <td>{listing?.agent_id?.zipcode}</td>
                          <td>{moment(listing.createdAt).format('DD MMM YYYY')}</td>
                          {listing.status === 'pending' ||
                          listing.status === 'rejected' ? (
                            <td>
                              <Badge color="danger">{listing.status}</Badge>
                            </td>
                          ) : (
                            <td>
                              <Badge color="success">{listing.status}</Badge>
                            </td>
                          )}

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#!"
                                  onClick={() => this.toggleModal(listing?.agent_id?.slug)}
                                >
                                  View
                                </DropdownItem>
                                {listing.status === 'pending' ||
                                listing.status === 'rejected' ? (
                                  <div>
                                    <DropdownItem
                                      href="#!"
                                      onClick={() =>
                                        this.approveClaim(listing?.slug)
                                      }
                                    >
                                      Approve
                                    </DropdownItem>
                                  </div>
                                ) : (
                                  <DropdownItem
                                    href="#!"
                                    onClick={() => this.rejectClaim(listing?.slug)}
                                  >
                                    Reject / Cancel Approval
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ) : (
                        <tr>No Data</tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Modal
                  show={this.state.isOpen}
                  onClose={() => this.toggleModal(this.id)}
                >
                  <Table className="table">
                    <thead>
                      <tr className="btn-primary">
                        <th colSpan="2">Listing Details</th>
                      </tr>
                    </thead>
                    {this.state.listingData &&
                      this.state.selectedAgentDetail && (
                        <tbody>
                          <tr>
                            <th>Agents Name </th>
                            <td>
                              {this.state.selectedAgentDetail?.first_name}{' '}
                              {this.state.selectedAgentDetail?.last_name}
                            </td>
                          </tr>{' '}
                          <tr>
                            <th>Company Email </th>
                            <td>{this.state.selectedAgentDetail?.email}</td>
                          </tr>
                          <tr>
                            <th>Status </th>
                            <td>{this.state.listingData.status}</td>
                          </tr>
                          <tr>
                            <th>Job Role </th>
                            <td>{this.state.listingData.job_role}</td>
                          </tr>
                          <tr>
                            <th>Company Size </th>
                            <td>{this.state.listingData.company_size}</td>
                          </tr>
                          <tr>
                            <th>Company Name </th>
                            <td>{this.state.listingData.company_name}</td>
                          </tr>
                          <tr>
                            <th>Company Revenue </th>
                            <td>{this.state.listingData.company_revenue}</td>
                          </tr>
                          <tr>
                            <th>Organization Type </th>
                            <td>{this.state.listingData.organization_type}</td>
                          </tr>
                          <tr>
                            <th>Annual Tax </th>
                            <td>{this.state.listingData.annual_tax}</td>
                          </tr>
                        </tbody>
                      )}
                  </Table>
                </Modal>

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
