import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getQuestions, deleteQuestion, getQuestionsPagination } from '../../redux/_actions/questions/index';
import moment from "moment";

import Pagination from "react-js-pagination";

import {
    Card, 
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    Container,
    Row,
    Button
  } from "reactstrap";

  // core components
import Header from "components/Headers/Header.js";
const ListQuestions = (props) => {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.questions.questions)
  const [activePage, setActivePage] = useState(1);
  const handlePageChange = async (pageNumber)  => {
    await dispatch(getQuestionsPagination(pageNumber))
  }
  useEffect(() => {
    dispatch(getQuestions());
  }, [dispatch]);

  const createMarkup = (body) => {
    return { __html: body };
  };

    return (
        <>
        <Header />
            {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
              <CardHeader className="border-0">
                  <Link style={{float: 'right'}} to="/admin/question/categories">
                    <Button >
                      Question Categories
                    </Button>
                  </Link>
                </CardHeader>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Questions</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Body</th>
                      <th scope="col">Date Created</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                      {
                        questions?.data?.map((question, index)=>(

                        <tr key={index}>
                        {/* <td>{question.category_id}</td> */}
                        <td>{question.first_name}</td>
                        <td>{question.last_name}</td>
                        <td>{question.phone}</td>
                          <td dangerouslySetInnerHTML={createMarkup(question.body)}></td>
                        {/* <td>{question.details}</td> */}
                        <td>{moment(question.createdAt).format('MMM-DD-YYYY')}</td>


                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <Link  to={`/admin/question/resource/edit/${question?.slug}`}>
                              <DropdownItem
                              >
                                Edit now now
                              </DropdownItem>
                            </Link>
                            <DropdownItem
                              href="#!"
                              onClick={() => dispatch(deleteQuestion(question.slug))}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                      </tr>
                        ))
                      }

                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      activePage={questions?.currentPage}
                      itemsCountPerPage={questions?.perPage}
                      totalItemsCount={questions?.totalData}
                      pageRangeDisplayed={questions?.totalPage}
                      onChange={handlePageChange}
                      itemClass='page-item'
                      linkClass='page-link'
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
        </>
    )
}

export default ListQuestions;
