import axiosInstance from '../../../axiosInstance';
import {
    GET_ARTICLES,
    DELETE_ARTICLE,
    CREATE_ARTICLE,
    UPDATE_ARTICLE,
    APPROVE_ARTICLE
} from '../../types';

// React Notification
import { NotificationManager } from 'react-notifications';

export const getArticles = () => async dispatch =>{
    
    try{
      const res = await axiosInstance.get('/articles/index');
      dispatch ({ 
          type : GET_ARTICLES,
          payload : res.data.data,
          
      }); 
      
    }
    catch(error){}
}

// Add New Article Action
export const addArticle = article => async dispatch => {
 
  try{
    console.log(article, 'test')
    const res = await axiosInstance.post('/articles/admin/create', article);
    dispatch ({
        type: CREATE_ARTICLE,
        payload: res.data 
    });
    NotificationManager.success('Article added successfully !','Success!', 2000);
      // window.setTimeout(function(){window.location.reload()}, 700);
  }
  catch(error){
    alert(error)
  }
}

// Update Article Action
export const updateArticle = (article, slug) => async dispatch => {
  
  try{
    const res = await axiosInstance.patch(`/articles/admin/update/${slug}`, article);
    NotificationManager.success('Article updated successfully !','Success!', 2000);
  }
  catch(error){
    alert(error?.response?.data?.error.message ?? error?.message)
  }
}

// Delete Article
export const deleteArticle = (id) => async dispatch => {
  
  try{
   await axiosInstance.delete(`/articles/${id}`);
    dispatch({
        type: DELETE_ARTICLE,
        payload: id
    })
    NotificationManager.success('Article deleted successfully !','Success!', 2000);
      window.setTimeout(function(){window.location.reload()}, 700);
  }
  catch(error){
    alert(error?.response?.data?.error.message ?? error.message)
  }
}

export const uploadExcelFiles = async (file) => {
  try{
    await axiosInstance.post('/agent/agents/upload/new/enrolled-agent', file);
    window.setTimeout(function(){window.location.reload()}, 700);
  } catch(error){
    alert(error?.response?.data?.error?.message ?? error?.message)
  }
}

export const uploadActivatedExcelFiles = async (file) => {
  try {
    await axiosInstance.post('/agents/excel-list/activate', file);
    window.setTimeout(function(){window.location.reload()}, 700);
  } catch (error) {
    alert(error?.response?.data?.error?.message ?? error?.message)
  }
}
export const uploadDeactivatedExcelFiles = async (file) => {
  try {
    await axiosInstance.post('/agents/excel-list/deactivate', file);
    window.setTimeout(function(){window.location.reload()}, 700);
  } catch (error) {
    alert(error?.response?.data?.error?.message ?? error?.message)
  }
}

export const approveArticle = article => async dispatch => {
  
  try{
    const res = await axiosInstance.post(`/articles/approve/${article.get("id")}`, article);
    dispatch({
        type: APPROVE_ARTICLE,
        payload: res.data
    })
  }
  catch(error){
    alert(error?.response?.data?.error.message ?? error.message)
  }
}
