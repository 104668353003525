import React, {useRef, useEffect, useState} from 'react'
//import {addArticleCategory} from '../../../redux/_actions/articles/category/index'

import { Editor } from '@tinymce/tinymce-react';
// reactstrap components
import {
    FormGroup,
    Card,
    CardHeader,
    Form,
    Label,
    Input,
    Button,
    Container,
    Row,
    Col,
    FormText
  } from "reactstrap";
  // core components
  import Header from "../../../components/Headers/Header.js";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getArticleCategories } from 'redux/_actions/articles/category/index.js';

import {getArticles} from '../../../redux/_actions/articles/article';
import { updateArticle } from 'redux/_actions/articles/article/index.js';
import axiosInstance from 'redux/axiosInstance/index.js';

  const EditArticle = props => {

    // Articles and categories from redux
    const [article, setArticle] = useState(null);
    const categories = useSelector(store => store.categories.categories, shallowEqual);
    const dispatch = useDispatch();
    const body = useRef(article?.body);

    // Fetch Categories and Articles on mount
    useEffect(() => {
      dispatch(getArticleCategories());
      dispatch(getArticles());
    }, [dispatch]);
    
    useEffect(() => {
      getParticularArticle();
    }, [])
    // Categories select options
    const catOptions = categories?.map((cat, i) => <option 
      key={`opt-${i}`} 
      selected={article?.category_id === cat._id} 
      value={cat._id}>
        {cat.title}
      </option>);
    
    // Description field update
    const handleEditorChange = content => {
      body.current = content;
    }
    const getParticularArticle  = async() => {
      try {
        const data = await axiosInstance.get(`/articles/admin/view/${props.match.params.id}`);
        setArticle(data.data.data)
      } catch(error) {}
    }

    /* Submit New Article */
    const handleSubmit = e =>  {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;

      if (body.current?.length < 30){
        alert("Article Body Content is too short or empty");
      }
      else if (form.checkValidity()) {
        const formData = new FormData(form);
        formData.append("id", props.match.params.id);
        formData.append('agent_id', JSON.parse(localStorage.getItem('authData'))?.id);
        formData.append("body", body.current);
        let object = {};
        formData.forEach(function(value, key){
            object[key] = value;
        });
        dispatch(updateArticle(formData, article.slug));
        props.history.push("/admin/articles/");
      }
    }
  
    return (
        <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Edit Article</h3>
                </CardHeader>
                <Form  onSubmit={handleSubmit}>
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="Title">Title</Label>
                      <Input type="text" name="title"  id="title" defaultValue={article?.title} />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="category">Category</Label>

                        <Input type="select"  id="exampleSelect"
                            
                            className="mr-sm-2"
                            name="category_id"
                            value={article?.category_id }
                          >
                            <option value=""></option>
                            {catOptions}
                        </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="category">Status</Label>

                        <Input type="select" 
                            className="mr-sm-2"
                            name="status"
                            id="status"
                          >
                            <option value="approved">Approve</option>
                            <option value="pending">Pend</option>
                            <option value="rejected">Reject</option>
                          
                        </Input>
                    </Col>
                  </FormGroup>
              
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="Body">Body</Label>
                     
                      <Editor
                            initialValue={article?.body}
                            init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={handleEditorChange}
                            id="body"
                            name="body"
                        />
                    </Col>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <Col sm={12}>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                    name="featured"
                    value="1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Featured </span>
                  </label>
                </div>
              
                    </Col>
                  </FormGroup>
                  <FormGroup>
                  <Col sm={12}>
                  <Label for="exampleFile">Current Image</Label>
                  <div className="current_image" style={{ width: '500px', objectFit: 'cover'  }}>
                            <img src={article?.image_url} alt="" style={{ width: '100%'}}/>
                  </div>
                  </Col>
                  </FormGroup>
                  <FormGroup>
                  <Col sm={12}>
                    <Label for="exampleFile">Upload Image</Label>
                    <Input type="file" name="file" id="exampleFile" />
                    <FormText color="muted">
                        Accepted file types are: png, jpeg or jpg.
                    </FormText>
                    </Col>
                    </FormGroup>
                  <FormGroup>
                    <Col sm={12}>
                  <Button className="btn btn-primary mr-2">Submit</Button>
                  </Col>
                    </FormGroup>
                </Form>
              </Card>
            </div>
          </Row>
         
        </Container>
      </>
        )
    }
 export default EditArticle;

