import React, { Component } from 'react';
import moment from 'moment';
import Modal from './Modal';
// React Notification
import { NotificationManager } from 'react-notifications';
import axiosInstance from '../../redux/axiosInstance';
import Pagination from 'react-js-pagination';
import { CSVLink } from "react-csv";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row
} from 'reactstrap';
// core components

import Header from 'components/Headers/Header.js';
import UserService from './UserService';

import LoadingShow from '../../components/LoadingShow';

export default class ListUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      error: null,
      users: [],
      userData: {},
      response: {},
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 3,
      search: '',
      loading: false
    };
    this.deactivateUser = this.deactivateUser.bind(this);
    this.activateUser = this.activateUser.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  deactivateUser(id) {
    UserService.deactivateUser(id).then((res) => {
      this.setState({
        users: this.state.users.filter((user) => user.id !== id)
      });
      NotificationManager.success(
        'User deactivated successfully !',
        'Success!',
        2000
      );
      window.setTimeout(function () {
        window.location.reload();
      }, 700);
    });
  }

  activateUser(id) {
    UserService.activateUser(id).then((res) => {
      this.setState({
        users: this.state.users.filter((user) => user.id !== id)
      });
      NotificationManager.success(
        'User activated successfully !',
        'Success!',
        2000
      );
      window.setTimeout(function () {
        window.location.reload();
      }, 700);
    });
  }

  toggleModal(id) {
    axiosInstance.get(`/user/${id}`).then((response) => {
      this.setState({
        userData: response.data.data.data
      });
    });

    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentDidMount() {
    this.setState({ loading: true });
    axiosInstance
      .get('/admin/user/list')
      .then((response) => {
        this.setState({
          users: response.data.data,
          itemsCountPerPage: response.data.perPage,
          totalItemsCount: response.data.total,
          activePage: response.data.page,
          loading: false
        });
      })
      .catch((e) => this.setState({ loading: false }));

      axiosInstance.get('/admin/user/list/all/users').then(res => {
        this.setState({
          allUsers: res?.data?.data
        })
      })
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber,
      loading: true
    });
    var query =`/user/index?page=${pageNumber}`

    axiosInstance
      .get(query)
      .then((response) => {
        this.setState({
          users: response.data.data.data,
          itemsCountPerPage: response.data.data.perPage,
          totalItemsCount: response.data.data.total,
          activePage: response.data.data.current_page,
          loading: false
        });
      })
      .catch((e) => this.setState({ loading: false }));
  }

  handleSearchChange(e) {
    var search = e.target.value;
    this.setState({
      search: search,
      loading: true
    });
    var query = search === '' ? `/user/` : `/user/?search=${search}`;

    axiosInstance
      .get(query)
      .then((response) => {
        this.setState({
          users: response.data.data,
          itemsCountPerPage: response.data.perPage,
          totalItemsCount: response.data.total,
          activePage: response.data.page,
          loading: false
        });
      })
      .catch((e) => this.setState({ loading: false }));
  }

  render() {

    const headers = [
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name" },
      { label: "Phone", key: "phone" },
      { label: "Email", key: "email" },
      { label: "Activated", key: "activated" },
      
    ];
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                  <a className="mb-0" href="/admin/user/upload">Upload New user</a>
                </CardHeader> */}
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    List of users
                  </h3>
                  {
                    this.state.allUsers && 
                  <CSVLink data={this.state.allUsers} filename={`users-list`} headers={headers}>{`Export-Users-List`}</CSVLink>
                  }
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Last Login</th>
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {!this.state.loading ? (
                      this.state.users.length === 0 ? (
                        <tr
                          dangerouslySetInnerHTML={{
                            __html: LoadingShow('No result found ', '7')
                          }}
                        />
                      ) : (
                        this.state.users.map((user) => {
                          return (
                            <tr key={user.id}>
                              <td>
                                {' '}
                                {user._id}
                              </td>

                              <td>{user.first_name}</td>
                              <td>{user.last_name}</td>
                              <td>{user.email}</td>
                              <td>{moment(user.createdAt).fromNow()}</td>
                              {user.isActive ? (
                                <td>
                                  <Badge color="success">Active</Badge>
                                </td>
                              ) : (
                                <td>
                                  <Badge color="danger">Deactivated</Badge>
                                </td>
                              )}

                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#!"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href="#!"
                                      onClick={() => this.toggleModal(user.id)}
                                    >
                                      View
                                    </DropdownItem>
                                    {user.is_activated === 1 ? (
                                      <DropdownItem
                                        href="#!"
                                        onClick={() =>
                                          this.deactivateUser(user.id)
                                        }
                                      >
                                        Deactivate
                                      </DropdownItem>
                                    ) : (
                                      <DropdownItem
                                        href="#!"
                                        onClick={() =>
                                          this.activateUser(user.id)
                                        }
                                      >
                                        Acivate
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      )
                    ) : (
                      <tr
                        dangerouslySetInnerHTML={{
                          __html: LoadingShow('Loading...', '7')
                        }}
                      />
                    )}
                  </tbody>
                </Table>
                <Modal
                  show={this.state.isOpen}
                  onClose={() => this.toggleModal(this.id)}
                >
                  <Table className="table">
                    <thead>
                      <tr className="btn-primary">
                        <th colSpan="2">User Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>First Name </th>
                        <td>{this.state.userData.first_name}</td>
                      </tr>{' '}
                      <tr>
                        <th>Last Name </th>
                        <td>{this.state.userData.last_name}</td>
                      </tr>{' '}
                      <tr>
                        <th>Email </th>
                        <td>{this.state.userData.email}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Modal>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
