import React from "react";
import CreateRole from "./createRole";
import CreatePermission from "./createPermission";
import axiosInstance from "redux/axiosInstance";
import Header from "components/Headers/Header.js";
import Modal from "views/agents/Modal";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  
  Table,
  Container,
  Row,
  Form,
  FormGroup, Button, Label, Input, Col
} from "reactstrap";
class Setting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            isModalOpen: false,
            isAddRoleOpen: false,
            isAddQuestionTypes: false,
            isAddPermissionOpen: false,
            permissions: [],
            permission: {
                name: "",
                code: "",
                description: "",
                isActive: true
            },
            roleBody: {
                name: "",
                accessFor: "",
                permissions: []
            },
            questionTypes: {
                title: "",
                description: "",
            },
            isLoading: false
        }
    }

    componentDidMount () {
        this.fetchAllRoles();
        this.fetchAllPermissions();
    }


    fetchAllPermissions = async() => {
        try {
            const permissions = await axiosInstance.get('/admin/permission/list');
            this.setState(permissions.data.data)
        } catch (error) {}
    }
    fetchAllRoles = async() => {
        try {
            const roles = await axiosInstance.get('/enum/role/all/roles');
            this.setState({
                roles: roles.data.data
            });
        } catch (error) {}
    }

    handleAddRolesModel = () => {
        this.setState({
            isModalOpen: true,
            isAddPermissionOpen: false,
            isAddRoleOpen: true,
            isAddQuestionTypes: false
        })
    }

    handleAddQuestionTypesModel = () => {
        this.setState({
            isModalOpen: true,
            isAddPermissionOpen: false,
            isAddRoleOpen: false,
            isAddQuestionTypes: true
        })
    }

    handleAddPermissionModel = () => {
        this.setState({
            isModalOpen: true,
            isAddPermissionOpen: true,
            isAddRoleOpen: false,
            isAddQuestionTypes: false
        })
    }

    handleCloseModal = () => {
        this.setState({
            isModalOpen: false,
            isAddPermissionOpen: false,
            isAddRoleOpen: false
        })
    }

    handlePermissionSubmit = async(e) => {
        try {
            e.preventDefault();
            this.setState({isLoading: true})
            const body = this.state.permission;
            const response = await axiosInstance.post('/admin/permission/create', body);
            if(response.data.statusCode == 201 || response.data.statusCode == 200) {
                this.setState({isLoading: false}, () => this.handleCloseModal());
            }
        } catch (error) {}
    }

    handleRoleSubmit = async(e) => {
        try {
            e.preventDefault();
            this.setState({isLoading: true})
            const body = this.state.roleBody;
            const response = await axiosInstance.post('/admin/role/create', body);
            if(response.data.statusCode == 201 || response.data.statusCode == 200) {
                this.setState({isLoading: false}, () => this.handleCloseModal());
            }
        } catch (error) {}
    }

    handleQuestionTypeSubmit = async(e) => {
        try {
            e.preventDefault();
            this.setState({isLoading: true})
            const body = this.state.questionTypes;
            const response = await axiosInstance.post('/questions/comment/create/options', body);
            if(response.data.statusCode == 201 || response.data.statusCode == 200) {
                this.setState({isLoading: false}, () => this.handleCloseModal());
            }
        } catch (error) {}
    }

    handleOnChangePermission = (e)=> {
        const {name, value} = e.target;
        this.setState({
           ...this.state,  permission: {...this.state.permission, [name]: value}
        })
    }

    handleOnChangeRole = (e)=> {
        const {name, value} = e.target;
        this.setState({
           ...this.state,  roleBody: {...this.state.roleBody, [name]: value}
        })
    }

    handleOnChangeQuestionTypeTitle = (e)=> {
        const {name, value} = e.target;
        this.setState({
           ...this.state,  questionTypes: {...this.state.questionTypes, [name]: value}
        })
    }
    render() {
        return (
            <>
            <Header />
            <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Button
                                onClick={this.handleAddRolesModel}
                                style={{ float: 'left', cursor: 'pointer' }}
                                color="info"
                            >
                                Add Role
                            </Button>
                            <Button
                                onClick={this.handleAddQuestionTypesModel}
                                style={{ float: 'left', cursor: 'pointer' }}
                                color="info"
                            >
                                Add QuestionType
                            </Button>
                            <Button
                                onClick={this.handleAddPermissionModel}
                                style={{ float: 'right', cursor: 'pointer' }}
                                color="info"
                            >
                                Add Permission
                            </Button>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Access for</th>
                                <th scope="col">isActive</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !!this.state.roles.length && this.state.roles.map((obj, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{obj._id}</td>
                                                <td>{obj.name}</td>
                                                <td>{obj.accessFor}</td>
                                                <td>{obj.isActive ? <Badge color="success">Yes</Badge>: <Badge color="danger">No</Badge>}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            </Table>
                                <Modal show={this.state.isModalOpen} onClose={this.handleCloseModal}>
                                    {
                                        this.state.isAddPermissionOpen && (
                                            <Form onSubmit={this.handlePermissionSubmit}>
                                                <h2>Add Permission</h2>
                                                <Col lg={12} md={12} sm={12}>
                                                    <FormGroup>
                                                            <Label for="name">Name</Label>
                                                            <Input onChange={this.handleOnChangePermission} id="name" type="text" placeholder="enter permission name" name="name" />
                                                            <Label for="code">Code</Label>
                                                            <Input id="code" onChange={this.handleOnChangePermission}  type="text" placeholder="enter permission code" name="code"/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                            <Label for="description">Description</Label>
                                                            <Input id="description" name="description" onChange={this.handleOnChangePermission}  type="text" placeholder="enter description" />
                                                    </FormGroup>
                                                    <FormGroup>
                                                    <Col sm={12} mt={5}>
                                                        <Button type="submit" className="btn btn-primary mr-2">{this.state.isLoading ? 'Submiting...': 'Submit'}</Button>
                                                    </Col>
                                                    </FormGroup>
                                                </Col>

                                            </Form>
                                        )
                                    }

                                    {
                                        this.state.isAddRoleOpen && (
                                            <Form onSubmit={this.handleRoleSubmit}>
                                                <h2>Add Permission</h2>
                                                <Col lg={12} md={12} sm={12}>
                                                    <FormGroup>
                                                            <Label for="name">Name</Label>
                                                            <Input onChange={this.handleOnChangeRole} id="name" type="text" placeholder="enter role name" name="name" />
                                                            <Label for="accessFor">Access For</Label>
                                                            <Input id="accessFor" onChange={this.handleOnChangeRole}  type="text" placeholder="enter Access for" name="accessFor"/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                            <Label for="permissions">Permissions</Label>
                                                            <Input id="permissions" name="permissions" onChange={this.handleOnChangeRole}  type="select" multiple placeholder="enter permissions" >{
                                                                this.state.permissions.length > 0 && 
                                                                this.state.permissions.map((obj, index) => {
                                                                    return (
                                                                        <option value={obj._id}>{obj.name}</option>
                                                                    )
                                                                })
                                                            }</Input>
                                                    </FormGroup>
                                                    <FormGroup>
                                                    <Col sm={12} mt={5}>
                                                        <Button type="submit" className="btn btn-primary mr-2">{this.state.isLoading ? 'Submiting...': 'Submit'}</Button>
                                                    </Col>
                                                    </FormGroup>
                                                </Col>

                                        </Form>
                                        )
                                    }

{
                                        this.state.isAddQuestionTypes && (
                                            <Form onSubmit={this.handleQuestionTypeSubmit}>
                                                <h2>Add Question Types</h2>
                                                <Col lg={12} md={12} sm={12}>
                                                    <FormGroup>
                                                            <Label for="title">Title</Label>
                                                            <Input required={true} onChange={this.handleOnChangeQuestionTypeTitle} id="title" type="text" placeholder="enter title name" name="title" />
                                                    </FormGroup>
                                                    <FormGroup>
                                                            <Label for="description">Description</Label>
                                                            <Input required={true} onChange={this.handleOnChangeQuestionTypeTitle} id="description" type="text" placeholder="enter description" name="description" />
                                                    </FormGroup>
                                                    <FormGroup>
                                                    <Col sm={12} mt={5}>
                                                        <Button type="submit" className="btn btn-primary mr-2">{this.state.isLoading ? 'Submiting...': 'Submit'}</Button>
                                                    </Col>
                                                    </FormGroup>
                                                </Col>

                                        </Form>
                                        )
                                    }
                                </Modal>
                            
                        </Card>
                    </div>
                </Row>
            </Container>
            </>
        );
    }
}

export default Setting;
