import axiosInstance from '../../../redux/axiosInstance';

class ArticleService {

    deleteArticle(articleId){
        return axiosInstance.delete(`/admin/article/${articleId}`);
    }

    updateArticle(articleId, article){
        return axiosInstance.put(`/articles/admin/update/${articleId}`, article);
    }

    approveArticle(articleId){
        return axiosInstance.patch(`/articles/admin/update/${articleId}`, {"status": "approved"});
    }

    rejectArticle(articleId){
        return axiosInstance.patch(`/articles/admin/update/${articleId}`, {"status": "rejected"});
    }

  deleteArticle(articleId){
    return axiosInstance.delete(`/articles/admin/delete/${articleId}`);
  }
}

export default new ArticleService()
