
import React,{useEffect, useState} from "react";
import { Redirect } from "react-router-dom";
import axiosInstance from "../../redux/axiosInstance/index";
import Avatar from "../../assets/img/avatar.png"
// import { updateProfile } from "../../redux/_actions/auth";
import { NotificationManager } from 'react-notifications';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";

const Profile = () => {
  const [profile, setProfile] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading,setLoading ] = useState(false)
  
  const handleChange = e => {
    e.persist(); 
    setFormData(prevState => { 
      return {
        ...prevState,
        [e.target.name]: e.target.value
      };
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true)
    axiosInstance.post("/admin/update-profile", formData)
      .then(res => res.data)
      .then(res => {
          setLoading(false)
          NotificationManager.success(`Profile Updated}`,'Success!', 2000);

         
          <Redirect to="/admin/user-profile/" />
       })
      .catch(error => {
        setLoading(false)
        NotificationManager.error(`${error?.response?.data?.error?.message ??  'An error occured, please try again later.'}`,'Error!', 2000);
      });
   
  };

  useEffect(() => { 
    axiosInstance.get(`user/profile`)
      .then(res => res.data)
      .then(res => {
        const profile = res.data;
        setProfile(profile);
      })
  }, []);
   
   /* Update Profile */
  // const handleSubmit = e =>  {
  //   e.preventDefault();
  //   e.stopPropagation();
    
  //   const form = e.currentTarget;
  //   if (form) {
  //     const formData = new FormData(form);
  //     // dispatch(updateProfile(formData));
  //     <Redirect to="/admin/user-profile/" />
  //   }
  // }
    return (
      <> 
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={Avatar}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                    
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                    {profile.first_name} {profile.last_name}
                      {/* <span className="font-weight-light">, 27</span> */}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {profile.email}
                    </div>
                 
                   
              
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My account</h3>
                    </Col>
                    
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <h6 className="heading-small text-muted mb-4" >
                      User information
                    </h6>
                    <div className="pl-lg-4">
                    
                      <Row>
                        
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder= {profile.email}
                              type="email"
                              name="email"
                              value={profile.email}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name" 
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue= {profile.first_name}
                              id="input-first-name"
                              placeholder="First name"
                              type="text"
                              name="first_name" 
                              value={formData.first_name || profile.first_name}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue= {profile.last_name}
                              id="input-last-name"
                              placeholder="Last name"
                              type="text"
                              name="last_name" 
                              value={formData.last_name || profile.last_name}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button size="sm" color="primary" type="submit" disabled={loading}>{ loading ? "Loading..." : "Save"}</Button>
                      
                     
                   
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    
                    
                  
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
 

export default Profile;
