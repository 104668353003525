import React,{useState, useEffect} from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";


import Header from "components/Headers/Header.js";
import axiosInstance from "redux/axiosInstance";
const Index = (props) => {

    const [allUsers, setAllUsers] = useState([]);

  const fetchAllUsers = async() => {
    try {
      const data = await axiosInstance.get(`/activities/admin/views/`);
    } catch(error) {}
  }
  useEffect(() => {
    fetchAllUsers()
  },[])

  
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="9">
            </Col>
          </Row>
         
        </Container>
      </>
    );
  }


export default Index;
