import React,{useState, useEffect} from "react";
import axiosInstance from "redux/axiosInstance";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
// import { API_URL } from "../../config";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";


const Header = () => {
  const [analytic, setAnalytic] = useState([]);
  const [userCount, setUserCount] = useState([]);
  const [articleCount, setArticleCount] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => { 
    setLoading(true)
    axiosInstance.get(`/activities/admin/views`)
    .then(res => res.data)
      .then(res => {
        setLoading(false)
        const analytic = res.data;
        setAnalytic(analytic);
      })
      .catch(e => setLoading(false))
  }, []);

  // useEffect(() => { 
  //   setLoading(true)
  //   axiosInstance.get(`/users/new-count`)
  //   .then(res => res.data)
  //     .then(res => {
  //       setLoading(false)
  //       const userCount = res.data;
  //       setUserCount(userCount);
  //     })
  //     .catch(e => setLoading(false))
  // }, []);

  // useEffect(() => { 
  //   setLoading(true)
  //   axiosInstance.get(`/articles/count`)
  //   .then(res => res.data)
  //     .then(res => {
  //       setLoading(false)
  //       const articleCount = res.data;
  //       setArticleCount(articleCount);
  //     })
  //     .catch(e => setLoading(false))
  // }, []);
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h6"
                            className="text-uppercase text-muted mb-0"
                          >
                            <Link className={`nav-link`} to="/admin/agents"> Total Agents</Link>
                           
                          </CardTitle>
                          {!loading ? (
                          <span className="h2 font-weight-bold mb-0">
                           {analytic.agent}
                          </span>
                          ) : (
                          <span className="blinking">
                          
                          </span>
                          )}
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h6"
                            className="text-uppercase text-muted mb-0"
                          >
                            <Link className={`nav-link`} to="/admin/users">
                            Current users

                            </Link>
                          </CardTitle>
                          {!loading ? (
                           <span className="h2 font-weight-bold mb-0">
                           {analytic.user}
                           </span>
                          ) : (
                          <span className="blinking">
                          
                          </span>
                          )}
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h6"
                            className="text-uppercase text-muted mb-0"
                          >
                            Articles
                          </CardTitle>
                          {!loading ? (
                          <span className="h2 font-weight-bold mb-0">
                             {analytic.articles}
                          </span>
                          ) : (
                          <span className="blinking">
                          
                          </span>
                          )}
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h6"
                            className="text-uppercase text-muted mb-0"
                          >
                            <Link className={`nav-link`} to="/admin/account-listing-claims"> Claim List</Link>
                           
                          </CardTitle>
                          
                          {!loading ? (
                          <span className="h2 font-weight-bold mb-0">
                           {analytic.claim_list}
                          </span>
                          ) : (
                          <span className="blinking">
                          
                          </span>
                          )}
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                     
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }


export default Header;
