import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import ResourceService from "./ResourceService"
// React Notification
import { NotificationManager } from 'react-notifications';
import axiosInstance from '../../../redux/axiosInstance';
import Pagination from "react-js-pagination";
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,   
    DropdownToggle,
    Button,
    Table,
    Input,
    Container,
    FormGroup,
    Row,
    Label,
    Col
  } from "reactstrap";
  // core components
import Header from "components/Headers/Header.js";
import { getResourcesCategories, getResourcesGroups } from 'redux/_actions/resources/category/index.js';


import Avatar from "../../../assets/img/avatar.jpg"
import { connect } from 'react-redux';

class ListResource extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resources: [],
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 1,
        pageRangeDisplayed: 3,
        search:''
    }
    this.deleteResource = this.deleteResource.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  deleteResource(id){
    ResourceService.deleteResource(id).then( res => {
      this.setState({resources: this.state.resources.filter(resource => resource._id !== id)});
      NotificationManager.success('Resource deleted successfully !','Success!', 2000);
      window.setTimeout(function(){window.location.reload()}, 700);
    });
  }
   
  componentDidMount() {
    axiosInstance.get('/resources/list')
      .then(response => {
        this.setState({
          resources: response.data.data,
          itemsCountPerPage: response.data.perPage,
          totalItemsCount: response.data.totalData,
          activePage: response.data.currentPage
        });
    });
    this.props.getResourcesCategories();
    this.props.getResourcesGroups();
    
  }

  handlePageChange(pageNumber) {
     this.setState({activePage: pageNumber});
     var query  = this.state.search === '' ? `/resources/list/?page=${pageNumber}` : `/resources/list/?search=${this.state.search}&page=${pageNumber}`
     
    axiosInstance.get(query)
        .then(response => {
            this.setState({
              resources: response.data.data,
              itemsCountPerPage: response.data.perPage,
              totalItemsCount: response.data.totalData,
              activePage: response.data.currentPage
            });
      });
    }
    
    handleSearchChange(e) {

      let searchStr = '';
      let name = e.target.name;
      let value = e.target.value

      if(name === 'group_id') {
        this.setState({
          group_id: value
        }, () => getData(`/resources/list?groups=${this.state.group_id }&categories=${this.state.category_id}&title=${this.state.title}`))
      } else if (name === 'category_id') {
        this.setState({
          category_id: value
        }, () => getData(`/resources/list?groups=${this.state.group_id}&categories=${this.state.category_id}&title=${this.state.title}`))
      } else {
        this.setState({
          title: value
        }, () =>  getData(`/resources/list?groups=${this.state.group_id}&categories=${this.state.category_id}&title=${this.state.title}`))
      }      
      const getData  = (searchStr) => axiosInstance.get(searchStr)
         .then(response => {
             this.setState({
              resources: response.data.data,
              itemsCountPerPage: response.data.perPage,
              totalItemsCount: response.data.totalData,
              activePage: response.data.currentPage
             });
       });
     }



  render() {

    const {categories, groups } = this.props

    const catOptions = categories?.map((cat, i) => <option key={`opt-${i}`} value={cat._id}>{cat.title}</option>);


    const groupsOptions = groups?.map((sponsor, i) => <option key={`opt-${i}`} value={sponsor._id}>{sponsor.name}</option>);

    return (
        <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Link to="/admin/resource/create">
                    <Button color="info">
                      Add New Resource +
                    </Button>
                    </Link>
                    <Link style={{float: 'right'}} to="/admin/resource/categories">
                    <Button >
                      Resource Categories
                    </Button>
                  </Link>
                  
                </CardHeader>
                <CardHeader className="border-0">
                  <h3 className="mb-0">News &amp; Resources</h3>

                  
                </CardHeader>
                <CardHeader>
                  <Row>
                  <Col lg={4}>
                   {
                    !!groups.length  && 
                    <FormGroup style={{float: 'left'}}>
                    <Input type="select" 
                            className="mr-sm-2"
                            name="group_id"
                            placeholder="Groups"
                            onChange={ (e) => this.handleSearchChange(e) }
                          >
                            <option value="" selected disabled>Groups</option>
                            {groupsOptions}
                        </Input>
                    </FormGroup>
                   }

                   </Col>

                   <Col lg={4}>
                    <FormGroup>
                        <input type="text"  className="form-control" onChange={ (e) => this.handleSearchChange(e) } placeholder="Search here"/>
                    </FormGroup>
                   </Col>

                  <Col lg={4} style={{float: 'right'}}>
                    <Row>
                      {
                      !!categories.length  && 
                      <FormGroup>
                        <Col>
                          {/* <Label for="category">Category</Label> */}
                          <Input type="select" 
                                  className="mr-sm-2"
                                  name="category_id"
                                  placeholder="category"
                                  onChange={ (e) => this.handleSearchChange(e) }
                                >

                                  <option value="" selected>Category</option>
                                  {catOptions}
                              </Input>
                        </Col>
                        

                      </FormGroup>
                    }
                      <FormGroup>

                        <Button color="info" onClick={() => {this.setState({search: ''})}}>
                            clear
                        </Button> 
                      </FormGroup>
                    </Row>
                   </Col>
                  </Row>

                </CardHeader>
               
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Category</th>
                      <th scope="col">Title</th>
                      <th scope="col">Image</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {
                      !!this.state.resources.length  && this.state.resources.length > 0 && this.state.resources.map(resource => {

                        return(
                        <tr key={resource._id}>
                          <td>{resource._id}</td>
                          <td>{resource?.category_id?.title}</td>
                          <td>{resource?.title}</td>
                          <td><img style={{ width: "120px" }} src={resource?.image_url && resource?.image_url[0]  ? resource?.image_url[0]?.completedUrl : Avatar } alt="avatar"></img></td>
                          <td>{resource?.actionLink}</td>
                          <td>{resource?.actionText}</td>
                          <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <Link  to={`/admin/resource/edit/${resource.slug}`}>
                            <DropdownItem
                            >       
                             
                              Edit
                                
                            </DropdownItem>
                            </Link>
                            <DropdownItem
                              href="#!"
                              onClick={ () => this.deleteResource(resource.slug)}
                            >
                              Delete
                            </DropdownItem> 
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                      </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                  <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.handlePageChange}
                      itemClass='page-item'
                      linkClass='page-link'
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
         
        </Container>
      </>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    categories: state.rescategories.rescategories,
    groups: state.rescategories.resGroups
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getResourcesCategories: () => dispatch(getResourcesCategories()),
    getResourcesGroups: () => dispatch(getResourcesGroups())
  }
}
export default connect(mapStateToProps, mapDispatchToProps) (ListResource)