import React, {Component } from 'react';
import Modal from './Modal';
// React Notification
import { NotificationManager } from 'react-notifications';
import axiosInstance from '../../redux/axiosInstance/';
import Pagination from "react-js-pagination";
import { CSVLink } from "react-csv";
// import AddAgent from './addAgent';
import {uploadExcelFiles, uploadActivatedExcelFiles, uploadDeactivatedExcelFiles} from "../../redux/_actions/articles/article";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Form,
  FormGroup, Button, Label, Input, Col
} from "reactstrap";
  // core components
import Header from "components/Headers/Header.js";
import AgentService from './AgentService';
import LoadingShow from '../../components/LoadingShow'

  export default class ListAgent extends Component {

    constructor(props) {
      super(props);
      this.state = {
          isOpen: false,
          isTable: false,
          isUploadExcel: false,
          error: null,
          agents: [],
          agentData: {},  
          response: {} ,
          activePage: 1,
          search: '',
          loading:false,
          itemsCountPerPage: 1,
          totalItemsCount: 1,
          pageRangeDisplayed: 5,
          filter: {},
          addAgentModal: false,
          excelAgents: null,
          fileType: 'all',
          first_name: '',
          isSearchedDone: false,
          last_name: '',
          allAgentsForExport : [],
          allClaimedAgentsForExport : [],
        claimed: ''
      }
      this.deleteAgent = this.deleteAgent.bind(this);
      this.activateStatus = this.activateStatus.bind(this)
      this.deactivateStatus = this.deactivateStatus.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this)
    }
  
    deleteAgent(id){
      AgentService.deleteAgent(id).then( res => {
        this.setState({agents: this.state.agents.filter(agent => agent._id !== id)});
        NotificationManager.success('Agent deleted successfully !','Success!', 2000);
        window.setTimeout(function(){window.location.reload()}, 700);
      });
    }

    openExcelFile() {
      this.setState({
        isOpen: true,
        isTable: false,
      })
    }

    getAllAgentsForExport = async() => {
      try {
        const data = await AgentService.getAllAgents();
        if(data.data) {
          this.setState({
            allAgentsForExport: data?.data?.data
          })
        }
      } catch (error) {
        console.log(error);
      }
    }

    getAllClaimedAgentsForExport = async() => {
      try {
        const data = await AgentService.getAllClaimedAgents();
        if(data.data) {
          this.setState({
            allClaimedAgentsForExport: data?.data?.data
          })
        }
      } catch (error) {
        console.log(error);
      }
    }

    activateStatus(id){
      // alert(id);return;
      AgentService.activateStatus(id).then( res => {
        this.setState({agents: this.state.agents.filter(agent => agent._id !== id)});
        NotificationManager.success('Agent Approved successfully !','Success!', 2000);
        window.setTimeout(function(){window.location.reload()}, 700);
      });
    }

    deactivateStatus(id){
      // alert(id);return;
      AgentService.deactivateStatus(id).then( res => {
        this.setState({agents: this.state.agents.filter(agent => agent._id !== id)});
        NotificationManager.success('Agent Deactivated successfully !','Success!', 2000);
        window.setTimeout(function(){window.location.reload()}, 700);
      });
    }

    toggleModal(id) { 
      axiosInstance.get(`/agent/agents/views/unique/${id}`).then(response => {
        this.setState({
          agentData: response.data.data,
        });
      });

      this.setState({
        isOpen: true,
        isTable: true
      });
  
    }

    handleFileType = (type) => {
      this.setState({
        fileType: type
      })
    }
     
    handleFetchAgents = () => {
      this.setState({loading: true })
      axiosInstance.get('/agent/agents/all')
        .then(response => {
          this.setState({
            agents: response.data.data,
            itemsCountPerPage: response.data.perPage,
            totalItemsCount: response.data.totalData,
            activePage: response.data.currentPage,
            loading: false
          })
         
      })
      .catch(e => this.setState({loading: false }));
    }
    componentDidMount() {
     this.handleFetchAgents();
     this.getAllAgentsForExport().then(r => {})
      this.getAllClaimedAgentsForExport().then(r => {})
    }
  
    handlePageChange = (pageNumber) => {
       this.setState({loading: true});
       var query  = `/agent/agents/all?page=${pageNumber}`
        
       axiosInstance.get(query)
          .then(response => {
              this.setState({
                  agents: response.data.data,
                  itemsCountPerPage:  response.data.perPage,
                  totalItemsCount: response.data.totalData,
                  activePage: response.data.currentPage ,
                  loading: false
              }, (data) => (data));
        })
        .catch(e => this.setState({loading: false }));
      }

    handleClaimed = ( claimedStatus ) => {
      this.setState({
        filter: {
          ...this.state.filter,
          isClaimed: claimedStatus
        }
      }, () => {
       axiosInstance.get(`/agent/agents/specific-search/?searchByClaimedUnclaimed=${this.state.filter.isClaimed === "isClaimed" ? 1: 0}`).then(response => {
        this.setState({agents: response.data?.data, itemsCountPerPage: response.data.perPage,
          totalItemsCount: response.data.totalData,
          activePage: response.data.currentPage, isSearchedDone: true})
       })
      })
    }

    handleSubmit = e => {
      e.preventDefault()
      e.stopPropagation()
      const form = e.currentTarget;
      const formData = new FormData(form)
      const { dispatch } = this.props
      if(this.state.fileType === 'activated') {
        dispatch(uploadActivatedExcelFiles(formData))
      } else if (this.state.fileType === 'deactivated') {
        dispatch(uploadDeactivatedExcelFiles(formData))
      } else {
        dispatch(uploadExcelFiles(formData))
      }
      this.setState({
        isOpen: false
      }, () => window.setTimeout(function(){window.location.reload()}, 700))
    }




    addAgent = () => {
      this.props.history.push('/admin/agent/add')
    }

    debounce = (func) => {
      let timer;
      return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          timer = null;
          func.apply(context, args);
        }, 500);
      };
    };

    handleSearchAgents =  this.debounce(async(e, type) => {
      try {
        if(type === 'firstname') {
          this.setState({
            ...this.state,
            first_name: e
          })
        } else if (type === 'lastname') {
          this.setState({
            ...this.state,
            last_name: e
          })
        } else if(type === 'search') {
          const response = await axiosInstance.get(`/agent/agents/specific-search?searchByFirstName=${this.state.first_name}&searchByLastName=${this.state.last_name}`);
          this.setState({agents: response.data?.data, itemsCountPerPage: response.data.perPage,
            totalItemsCount: response.data.totalData,
            activePage: response.data.currentPage, isSearchedDone: true})
        } else {
          this.handleFetchAgents();
          this.setState({
            isSearchedDone: false,
            first_name: '',
            last_name: ''
          })
        }
      } catch(error) {}
    }) 

    handleZipCodeLength = (zipcode) => {
        if(zipcode?.length >= 5) {
          return zipcode
        } else {
          for(let i = zipcode?.length; i < 5; i++) {
            zipcode= '0'+zipcode?.toString();
          }
          return zipcode
        }
    }

    render() {
      let agents  = this.state.agents
      const headers = [
        { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Address 1", key: "address1" },
        { label: "Address 2", key: "address2" },
        { label: "Phone", key: "phone" },
        { label: "City", key: "city" },
        { label: "State", key: "state" },
        { label: "Country", key: "country" },
        { label: "Zip code", key: "zipcode" },
        { label: "ID", key: "_id" }
      ];

      
    return (
        <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                <Button
                    onClick={() => this.addAgent()}
                    style={{ float: 'left', cursor: 'pointer' }}
                    color="info"
                  >
                    Add Admin
                  </Button>
                  <Button
                    onClick={() => this.openExcelFile()}
                    style={{ float: 'right', cursor: 'pointer' }}
                    color="info"
                  >
                    Upload Excel Files
                  </Button>
                </CardHeader>
                <CardHeader className="border-0 d-flex align-items-baseline justify-content-between">
                  <h3 className="mb-0">List of agents</h3>
                  {
                    this.state.allAgentsForExport && this.state.allAgentsForExport.length > 0 && 
                  <CSVLink data={this.state.allAgentsForExport} filename={'All-agents-excel'} headers={headers}>{`Export-Agents`}</CSVLink>
                  }
                  {
                    this.state.allClaimedAgentsForExport && this.state.allClaimedAgentsForExport.length > 0 &&
                    <CSVLink data={this.state.allClaimedAgentsForExport} filename={'All-agents-excel'} headers={headers}>{`Claim Agent`}</CSVLink>
                  }
                  <div className='col-md-6 d-flex'>
                    <input type='text' placeholder='Enter firstname' className='form-control col-md-4 mr-3' onChange={e => this.handleSearchAgents(e.target.value, 'firstname')} />
                    <input type='text' placeholder='Enter lastname' className='form-control col-md-4 mr-2' onChange={e => this.handleSearchAgents(e.target.value, 'lastname')} />
                    <Button
                    onClick={() => this.handleSearchAgents(null, this.state.isSearchedDone ? 'clear': 'search')}
                    style={{ float: 'left', cursor: 'pointer' }}
                    color="info"
                  >
                    {this.state.isSearchedDone ? 'Clear': 'Search'}
                  </Button>
                  </div>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-dark"
                      href="#pablo"
                      role="button"
                      size="sm"
                      color=""
                      onClick={e => e.preventDefault()}
                    >
                      Filter
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem href="#!">
                            <p>Claim Status</p>
                            <label htmlFor='isClaimed' onClick={() => this.handleClaimed ('isClaimed')}>
                              Claimed
                            </label> {" "}
                           <input type='radio' checked={this.state.filter?.isClaimed === "isClaimed"} value={"isClaimed"} id="isClaimed" name="isClaimed" />
                            <label htmlFor='isNotClaimed'  onClick={() => this.handleClaimed('isNotClaimed')}> &nbsp;
                              Not Claimed
                            </label> <input type='radio' checked={this.state.filter?.isClaimed === "isNotClaimed"} value={"isNotClaimed"} id="isNotClaimed"  name="isClaimed"/>
                        </DropdownItem>
                        <DropdownItem href="#!">
                            <button className='btn btn-primary' type='button' onClick={() => this.setState({filter: {}}, () => {
                              this.handlePageChange(1)
                            })}>Clear</button>
                        </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Country</th>
                      <th scope="col">Zip code</th>
                      <th scope="col">Status</th>
                      <th scope="col">Active</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {
                     !this.state.loading ? (this.state.agents.length === 0 ? <tr dangerouslySetInnerHTML={{__html: LoadingShow('No result found ','7')}} /> : agents.map(agent => {
                        return(
                        <tr key={agent._id}>
                          <td>{agent._id}</td>
                          <td>{agent.first_name}</td>
                          <td>{agent.last_name}</td>
                          <td>{agent.country}</td>
                          <td>{this.handleZipCodeLength(agent.zipcode)}</td>
                          {
                            agent.is_claimed === true ?
                          <td><Badge color="success">Claimed</Badge></td>
                            :
                           <td> <Badge color="danger">Not yet Claimed</Badge></td>
                          }
                          {
                            agent.is_activated === false ?
                          <td><Badge color="danger">Inactive</Badge></td>
                            :
                           <td> <Badge color="success">Active</Badge></td>
                          }
                          <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                              href="#!"
                              onClick={ () => this.toggleModal(agent._id)}
                            >
                              View
                            </DropdownItem>
                            {
                                agent.is_activated === false ?
                              <div>

                            <DropdownItem
                              href="#!"
                              onClick={ () => this.activateStatus(agent.slug)}
                            >
                              Activate
                            </DropdownItem>

                              </div>


                            :

                            <DropdownItem
                              href="#!"
                              onClick={ () => this.deactivateStatus(agent._id)}
                            >
                              Deactivate
                            </DropdownItem>
                            }


                          </DropdownMenu>
                        </UncontrolledDropdown>

                      </td>
                      </tr>
                        )
                      })) : (

                        <tr dangerouslySetInnerHTML={{__html: LoadingShow('Loading...','7')}} />
                      )
                    }
                    
                  </tbody>
                </Table>
                  {/* <Modal show={this.state.addAgentModal} onClose={this.addAgent}>
                      <AddAgent />
                  </Modal> */}
                <Modal show={this.state.isOpen || this.state.isTable}  onClose={ () => 
                  this.setState({
                    isTable: false,
                    isOpen: false
                  })
                }>
                  { this.state.isTable ? (
                    <Table className="table">
                      <thead>
                      <tr className="btn-primary"><th colSpan="2">Agent Details</th></tr>
                      </thead>
                      <tbody>

                      <tr>
                        <th>First Name </th><td>{this.state.agentData.first_name}</td>
                      </tr> <tr>
                        <th>Last Name </th><td>{this.state.agentData.last_name}</td>
                      </tr> <tr>
                        <th>Mobile No  </th><td>{this.state.agentData.phone}</td>
                      </tr> <tr>
                        <th>Address  </th><td>{this.state.agentData.address1}</td>
                      </tr> <tr>
                        <th>ZipCode  </th><td>{this.state.agentData.zipcode}</td>
                      </tr>
                      {/* <tr>
                        <th>Company Name  </th><td>{this.state.agentData.CompanyName}</td>
                      </tr>    */}
                      <tr>
                        <th>Country  </th><td>{this.state.agentData.country}</td>
                      </tr>
                      <tr>
                        <th>State  </th><td>{this.state.agentData.state}</td>
                      </tr>
                      <tr>
                        <th>City  </th><td>{this.state.agentData.city}</td>
                      </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <div>
                      <UncontrolledDropdown>
                          <DropdownToggle
                            className=""
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                          >
                            File Types
                          </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow">
                            <DropdownItem href="#!" onClick={() => this.handleFileType('activated')}>
                                Activated Agent
                            </DropdownItem>
                            <DropdownItem href="#!" onClick={() => this.handleFileType('deactivated')}>
                                Deactivated Agent
                            </DropdownItem>

                            <DropdownItem href="#!" onClick={() => this.handleFileType('all')}>
                                All Agents
                            </DropdownItem>
                        </DropdownMenu>
                        </UncontrolledDropdown>
                      <Form onSubmit={this.handleSubmit}>
                        <Col sm={12}>
                          {/* <input type='radio'  name='agent_type' className='' /> Activate Agent
                          <input type='radio'  name='agent_type' className=''/> Deactivate Agent
                          <input type='radio' name='agent_type'  className=''/> All agents Agent */}
                          <Label for="exampleFile">Upload Image</Label>
                          <Input type="file" name="file" id="exampleFile" accept="text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  />
                        </Col>
                        <FormGroup>
                          <Col sm={12} mt={5}>
                            <Button type="submit" className="btn btn-primary mr-2">Upload Excel File</Button>
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                  )}

                </Modal>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                  <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.handlePageChange}
                      itemClass='page-item'
                      linkClass='page-link'
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>

        
      </>
    )
  }
}
