import axiosInstance from '../../../axiosInstance';
import { GET_ALL_QUESTION_CATEGORIES, CREATE_QUESTION_CATEGORY, UPDATE_QUESTION_CATEGORY, DELETE_QUESTION_CATEGORY } from '../../types';

// React Notification
import { NotificationManager } from 'react-notifications';

export const getQuestionsCategories = () => async dispatch =>{

  const res = await axiosInstance.get('/questions/comment/list/options');
  dispatch ({ 
      type : GET_ALL_QUESTION_CATEGORIES,
      payload : res.data?.data?.data
  }); 
}

export const getQuestionsCategoriesPagination = (pageNumber) => async dispatch =>{

  const res = await axiosInstance.get(`/questions/comment/list/options?page=${pageNumber}`);
  dispatch ({
    type : GET_ALL_QUESTION_CATEGORIES,
    payload : res.data?.data
  });
}


export const addQuestionCategory = (category) => async dispatch => {
    const res = await axiosInstance.post('/questions/comment/create/options', category);
    dispatch ({
        type: CREATE_QUESTION_CATEGORY,
        payload: res.data
    });
    NotificationManager.success('Question Category Added successfully !','Success!', 2000);
      window.setTimeout(function(){window.location.reload()}, 700);
} 

//Update question Category
export const updateQuestionCategory = (cat, slug) => async dispatch => {
  console.log(cat)
    try{
      const res = await axiosInstance.patch(`/questions/comment/options/update/${slug}`, cat);
      dispatch ({
          type: UPDATE_QUESTION_CATEGORY,
          payload: res.data?.data
      });
      NotificationManager.success('Question Category Updated successfully !','Success!', 2000);
      window.setTimeout(function(){window.location.href = '/admin/question/categories'}, 700);
    }
    catch(error){
      NotificationManager.error('Question Category Could not update !','Error!', 2000);
    }
  }

  //Delete Question Category
  export const deleteQuestionCategory = (id) => async dispatch => {
  
    try{
      await axiosInstance.delete(`/question-categories/${id}`);
      dispatch({
          type: DELETE_QUESTION_CATEGORY
      })
      NotificationManager.success('Question Category Deleted Successfully !','Success!', 2000);
      window.setTimeout(function(){window.location.reload()}, 700);
    }
    
    catch(error){
      NotificationManager.error('Question Category could not be deleted !','Error!', 2000);
      window.setTimeout(function(){window.location.reload()}, 700);
    }
  }
