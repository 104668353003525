import axiosInstance from '../../axiosInstance';
// React Notification
import { NotificationManager } from 'react-notifications';
import {
  GET_ALL_QUESTIONS, DELETE_QUESTION, GET_QUESTION
} from '../types';

export const getQuestions = () => async dispatch =>{
    
    const res = await axiosInstance.get('/questions/list');
    dispatch ({ 
        type : GET_ALL_QUESTIONS,
        payload : res.data
    });   
}

export const updateQuestions = (payload, slug) => async dispatch =>{
  try {
    const res = await axiosInstance.patch(`/questions/update/${slug}`, payload);
    if (res.status === 200 || res.status === 201) {
      dispatch ({
        type : GET_ALL_QUESTIONS,
        payload : res.data.data
      });
      NotificationManager.success('Update was successful !','Success!', 2000);
      window.setTimeout(function(){window.location.href = '/admin/questions'}, 700);
    }
  } catch (e) {
    NotificationManager.success('Update failed!','Error!', 2000);
  }
}

export const getQuestionsPagination = (pageNumber) => async dispatch =>{

  const res = await axiosInstance.get(`/questions/list?page=${pageNumber}`);
  dispatch ({
    type : GET_ALL_QUESTIONS,
    payload : res.data
  });
}

export const getQuestionSingleView = (slug) => async dispatch => {
   const res = await axiosInstance.get(`/questions/view/${slug}`);
   dispatch({
     type: GET_QUESTION,
     payload: res?.data?.data
   })
}

//Delete Question
export const deleteQuestion = (id) => async dispatch => {
  
  try{
    const rest = await axiosInstance.delete(`/questions/delete/${id}`);
    if (rest.status === 200) {
      dispatch({
        type: DELETE_QUESTION
      })
      NotificationManager.success('Question Deleted Successfully !','Success!', 2000);
      window.setTimeout(function(){window.location.reload()}, 700);
    }
  }
  
  catch(error){
    console.log(error)
    // window.setTimeout(function(){window.location.reload()}, 700);
  }
}
