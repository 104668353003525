import React from "react";

class CreatePermission extends React.Component {
    render() {
        return (
            <div></div>
        )
    }
}

export default CreatePermission;