import React from "react";

class CreateRole extends React.Component {
    render() {
        return (
            <>
            </>
        )
    }
}

export default CreateRole;