export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
//Login
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOAD_USER = 'LOAD_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
//Logout
export const LOGOUT = 'LOGOUT';

//Article Categories
export const CREATE_ARTICLE_CATEGORY = 'CREATE_ARTICLE_CATEGORY';
export const GET_ARTICLE_CATEGORIES = 'GET_ARTICLE_CATEGORIES';
export const GET_ARTICLE_CATEGORY = 'GET_ARTICLE_CATEGORY';
export const UPDATE_ARTICLE_CATEGORY = 'UPDATE_ARTICLE_CATEGORY';
export const DELETE_ARTICLE_CATEGORY = 'DELETE_ARTICLE_CATEGORY';

//News & Articles
export const GET_ARTICLES = 'GET_ARTICLES';
export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const GET_ARTICLE = 'GET_ARTICLE';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const APPROVE_ARTICLE = 'APPROVE_ARTICLE';
export const REJECT_ARTICLE = 'REJECT_ARTICLE';

//Resources Category
export const CREATE_RESOURCE_CATEGORY = 'CREATE_RESOURCE_CATEGORY';
export const GET_RESOURCE_CATEGORIES = 'GET_RESOURCE_CATEGORIES';
export const GET_RESOURCE_CATEGORY = 'GET_RESOURCE_CATEGORY';
export const UPDATE_RESOURCE_CATEGORY = 'UPDATE_RESOURCE_CATEGORY';
export const DELETE_RESOURCE_CATEGORY = 'DELETE_RESOURCE_CATEGORY';

export const CREATE_RESOURCE_GROUP = 'CREATE_RESOURCE_GROUP';
export const GET_RESOURCE_GROUP = 'GET_RESOURCE_GROUP';
export const UPDATE_RESOURCE_GROUP = 'UPDATE_RESOURCE_GROUP';
export const DELETE_RESOURCE_GROUP = 'DELETE_RESOURCE_GROUP';

//Resources
export const GET_RESOURCES = 'GET_RESOURCES';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const GET_RESOURCE = 'GET_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';

//Sponsors
export const GET_ALL_SPONSORS = 'GET_ALL_SPONSORS';
export const CREATE_SPONSOR = 'CREATE_SPONSOR';
export const GET_SPONSOR = 'GET_SPONSOR';
export const UPDATE_SPONSOR = 'UPDATE_SPONSOR';
export const DELETE_SPONSOR = 'DELETE_SPONSOR';

//Claim Listing
export const GET_ALL_CLAIM_LISTING = 'GET_ALL_CLAIM_LISTING';
export const APPROVE_CLAIM_LISTING = 'APPROVE_CLAIM_LISTING';
export const CANCEL_CLAIM_LISTING = 'CANCEL_CLAIM_LISTING';

//Listing Requests
export const GET_ALL_LISTING_REQUEST = 'GET_ALL_LISTING_REQUEST';
export const GET_LISTING_REQUEST = 'GET_LISTING_REQUEST';
export const APPROVE_LISTING_REQUEST = 'APPROVE_LISTING_REQUEST';
export const REJECT_LISTING_REQUEST = 'REJECT_LISTING_REQUEST';


//Get Contacts
export const GET_ALL_CONTACTS = 'GET_ALL_CONTACTS';

//License Verification
export const GET_ALL_LICENSE_VERIFICATION = 'GET_ALL_LICENSE_VERIFICATION';
export const VIEW_LICENSE_VERIFICATION_DETAILS = 'VIEW_LICENSE_VERIFICATION_DETAILS';

//Subscription

//Offshore Team
export const GET_ALL_OFFSHORE_FORM_LIST = 'GET_ALL_OFFSHORE_FORM_LIST';
export const VIEW_OFFSHORE_FORM_DETAILS = 'VIEW_OFFSHORE_FORM_DETAILS';
export const DELETE_OFFSHORE_FORM = 'DELETE_OFFSHORE_FORM';
export const EXPORT_OFSHORE_LIST = 'EXPORT_OFSHORE_LIST';


//Ask an EA Questions
export const GET_ALL_QUESTIONS = 'GET_ALL_QUESTIONS';
export const GET_QUESTION = 'GET_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const SET_ANSWER = 'SET_ANSWER';

//Ask an EA Question Category
export const CREATE_QUESTION_CATEGORY = 'CREATE_QUESTION_CATEGORY';
export const GET_ALL_QUESTION_CATEGORIES = 'GET_ALL_QUESTION_CATEGORIES';
export const GET_QUESTION_CATEGORY = 'GET_QUESTION_CATEGORY';
export const DELETE_QUESTION_CATEGORY = 'DELETE_QUESTION_CATEGORY';
export const UPDATE_QUESTION_CATEGORY = 'UPDATE_QUESTION_CATEGORY';

//Agents
export const GET_ALL_AGENTS = 'GET_ALL_AGENTS';
export const GET_AGENT_DETAILS = 'GET_AGENT_DETAILS';
export const UPDATE_AGENT_DETAILS = 'UPDATE_AGENT_DETAILS';
export const DELETE_AGENT = 'DELETE_AGENT';
export const UPLOAD_AGENT = 'UPLOAD_AGENT';
export const ADD_AGENT = 'ADD_AGENT';

//Users
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const DELETE_USER = 'DELETE_USER';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const UPDATE_USER = 'UPDATE_USER';

//Faq
export const GET_FAQS = 'GET_FAQS';
export const GET_FAQ = 'GET_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';
export const CREATE_FAQ = 'CREATE_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const APPROVE_FAQ = 'APPROVE_FAQ';

//Config - Payment Information
export const GET_PAYMENT_DATA = 'GET_PAYMENT_DATA';
export const UPDATE_PAYMENT_DATA = 'UPDATE_PAYMENT_DATA';

//Admin Profile Update
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

//Settings
export const GET_LOGS = 'GET_LOGS';
export const DELETE_LOG = 'DELETE_LOG';

//Config - Staff Information
export const GET_ALL_STAFFS = 'GET_ALL_STAFFS';
export const CREATE_STAFF = 'CREATE_STAFF';
export const GET_STAFF_DETAILS = 'GET_STAFF_DETAILS';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const DELETE_STAFF = 'DELETE_STAFF';

//Config - Roles
export const GET_ALL_ROLE = 'GET_ALL_ROLE';
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE'; 
