import axios from "axios";

let error_message_defined = false;

function tokenRefresh(){
  return localStorage.getItem("token")
}

const fetchClient = () => {
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
}
});

 // Set the AUTH token for any request
 axiosInstance.interceptors.request.use(function (config) {

  const token = tokenRefresh();
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;

});

  function logoutAndRedirectToLogin() {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    window.location.href = '/auth/login'
  }



  async function refreshAccessToken() {
    const refresh = localStorage.getItem('refreshToken')
    let newToken
    try {
      if (refresh) {
        const res = await  axiosInstance.post('/auth/refresh', { refreshToken: refresh })
        if (res.status === 200) {
          newToken = res?.data?.data;
        }
      }
    } catch (e) {
      logoutAndRedirectToLogin()
    }
    return newToken;
  }

axiosInstance.interceptors.response.use((response) => {
  return response
}, 
async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    // originalRequest._retry = true;
    // try {
    //   const refreshedToken = await refreshAccessToken();
    //   if (refreshedToken?.accessToken) {
    //     localStorage.setItem('token', refreshedToken?.accessToken);
    //     localStorage.setItem('refreshToken', refreshedToken?.refreshToken);
    //     originalRequest.headers.Authorization = `Bearer ${refreshedToken?.accessToken}`;
    //     return axiosInstance(originalRequest);
    //   } else {
    //     logoutAndRedirectToLogin();
    //     return Promise.reject('Unable to refresh token');
    //   }
    // } catch (refreshError) {
    //   logoutAndRedirectToLogin();
    //   return Promise.reject(refreshError);
    // }
    logoutAndRedirectToLogin();
  }
  if (error.status === undefined || (error.response.status === 401 && !originalRequest._retry)) {
    const refreshedToken = await refreshAccessToken();
    if (refreshedToken?.accessToken) {
      localStorage.setItem('token', refreshedToken?.accessToken);
      localStorage.setItem('refreshToken', refreshedToken?.refreshToken);
      if(error_message_defined) return axios(originalRequest);
      error_message_defined = true
    } else {
      logoutAndRedirectToLogin();
      return Promise.reject('Unable to refresh token');
    }
  }
  return axios(originalRequest);
})

return axiosInstance;
}

export default fetchClient()
