import {
  DELETE_QUESTION,
  GET_ALL_QUESTIONS, GET_QUESTION, UPDATE_QUESTION
} from '../../_actions/types';

const initState = {
  error: null,
  questions:  [ ], 
  question : {},
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: true
}; 

export default function question (state = initState, action){
  const {type, payload} = action;
  switch (type) {
      case GET_ALL_QUESTIONS :
          return{
          ...state, 
          questions: payload,
          isAuthenticated: true,
          loading: false,
              
      }
      case GET_QUESTION:
        return {
          ...state,
          question : payload
        }
      // case ADD_question :
      //     return {
      //         ...state,
      //         data: [payload, ...state.data]

      //     } 

      case DELETE_QUESTION :
          return{
              ...state,
              questions: state.questions?.data?.filter(question => question.id !== payload)
          }
          
      case UPDATE_QUESTION:
        return {
          ...state,
          question: payload,
          questions : state.questions?.data.map(question => question.id === payload.id ? (question = payload) : question )
        }
      default : {
          return state
      }
  }
}
