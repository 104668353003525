import React, { useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  FormGroup,
  Card,
  CardHeader,
  Form,
  Label,
  Input,
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import Header from './../../components/Headers/Header';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getQuestionsCategories } from '../../redux/_actions/questions/category';
import { updateQuestions } from '../../redux/_actions/questions';
import axiosInstance from '../../redux/axiosInstance';

const QuestionEdit = props  => {
  const [question, setQuestion] = useState(null)
  const dispatch = useDispatch();
  const body = useRef(question?.body);
  // const loadQuestion = useSelector(store => store.questions.question, shallowEqual)
  const categories = useSelector(store => store.quecategories.quecategories, shallowEqual);
  const handleQuestionCategories = async () => {
    await dispatch(getQuestionsCategories())
  }

  const getSingleResource = async(slug) => {
    try {
      const data = await axiosInstance.get(`/questions/view/${slug}`)
      if(data.data.data) {
        setQuestion(data.data.data)
      }
    } catch (err) {}
  }

  useEffect(() => {
    getSingleResource(props.match.params.id)
    dispatch(handleQuestionCategories)
  }, [dispatch]);

  const catOptions = categories?.map((cat, i) => <option
    key={`opt-${i}`}
    selected={question?.questions_type?._id === cat._id}
    value={cat._id}>
    {cat.title}
  </option>);

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (body?.current?.length < 30){
      alert("Resource Body Content is too short or empty");
    } else if (form.checkValidity()) {
      const formData = new FormData(form);
      formData.append("body", body.current ?? question.content[0]);
      let object = {};
      formData.forEach(function(value, key){
        object[key] = value;
      });
      dispatch(updateQuestions(object, props.match.params.id));
    }
  }
  const handleEditorChange = content => {
    body.current = content;
  }
  console.log(question)
  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Edit Resource</h3>
              </CardHeader>
              <Form  onSubmit={handleSubmit}>
                <FormGroup>
                  <Col sm={12}>
                    <Label for="fname">First Name</Label>
                    <Input type="text" name="first_name" required  id="first_name" defaultValue={question?.first_name} />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={12}>
                    <Label for="lname">Last Name</Label>
                    <Input type="text" name="last_name" required  id="last_name" defaultValue={question?.last_name} />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={12}>
                    <Label for="phone">Phone</Label>
                    <Input type="text" name="phone" required  id="phone" defaultValue={question?.phone} />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={12}>
                    <Label for="questions_type">Category</Label>

                    <Input type="select"  id="exampleSelect"
                           required
                           className="mr-sm-2"
                           name="questions_type"
                           defaultValue={question?.questions_type?._id}
                    >
                      <option value=""></option>
                      {catOptions}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={12}>
                    <Label for="Body">Body</Label>

                    <Editor
                      initialValue={question?.body}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                      }}
                      onEditorChange={handleEditorChange}
                      id="body"
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={12}>
                    <Button className="btn btn-primary mr-2">Submit</Button>
                  </Col>
                </FormGroup>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )

}

export default QuestionEdit;
