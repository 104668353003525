import axiosInstance from '../../../redux/axiosInstance';

class ResourceService {

    deleteResource(resourceId){
        return axiosInstance.delete(`/resources/admins/delete/${resourceId}`);
    }
}

export default new ResourceService()
