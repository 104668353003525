
import React,{useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import {userLogout} from '../../redux/_actions/auth';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import axiosInstance from "../../redux/axiosInstance";
import Avatar from "../../assets/img/avatar.jpg"
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

const AdminNavbar = (props) => {

  const [profile, setProfile] = useState([]);
  const history = useHistory();

  useEffect(() => {
    axiosInstance.get("/user/profile")
      .then(res => {
        const profile = res.data.data;
        setProfile(profile);
      })
  }, []);

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    history.push("/")
  }
  return (
    <>
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <Link
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          to="/"
        >
          {props.brandText}
        </Link>
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={Avatar}
                  />
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">

                    {profile.first_name} {profile.last_name}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!  {profile.first_name} {profile.last_name}</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              {/* <DropdownItem to="/admin/configuration" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem> */}

              <DropdownItem divider />
              <DropdownItem onClick={logoutUser} href="#">
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

      </Container>
    </Navbar>
  </>
  )
}
AdminNavbar.propTypes = {
  auth: PropTypes.func.isRequired,
}


export default connect(null, {userLogout})(AdminNavbar);
