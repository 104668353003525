import React, { useEffect, useRef, useState } from 'react';

// reactstrap components
import {
    FormGroup,
    Card,
    CardHeader,
    Form,
    Label,
    Input,
    Button,
    Container,
    Row,
    Col
  } from "reactstrap";
  // core components
  import Header from "../../../components/Headers/Header.js";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {getQuestionsCategories} from '../../../redux/_actions/questions/category/index';
import { updateQuestionCategory } from '../../../redux/_actions/questions/category/index';
import axiosInstance from '../../../redux/axiosInstance';

  const EditCategoryQuestion = props => {
    const [questionCategory, setQuestionCategory] = useState(null)
    // faqs  from redux
    const dispatch = useDispatch();

    const description = useRef(questionCategory?.description);
    const getSingleResource = async(slug) => {
      try {
        const data = await axiosInstance.get(`/questions/comment/show/options/single/${slug}`)
        if(data.data.data) {
          setQuestionCategory(data.data.data)
        }
      } catch (err) {}
    }


    useEffect(() => {
      getSingleResource(props.match.params.id)
      dispatch(getQuestionsCategories());
    }, [dispatch]);
    
   
    
    /* Submit New Faq */
    const handleSubmit = e =>  {
      e.preventDefault();
      e.stopPropagation();
      const form = e.currentTarget;

      if (description?.current?.length < 3){
        alert("Resource Body Content is too short or empty");
      } else if (form.checkValidity()) {
        const formData = new FormData(form);
        formData.append("description", description.current ?? questionCategory.content[0]);
        let object = {};
        formData.forEach(function(value, key){
          object[key] = value;
        });

        dispatch(updateQuestionCategory(formData, props.match.params.id));
      }
    }
    return (
        <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Edit Question Category</h3>
                </CardHeader>
                <Form  onSubmit={handleSubmit}>
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="title">Title</Label>
                      <Input type="text" name="title" required  id="title" defaultValue={questionCategory?.title} />
                    </Col>
                  </FormGroup>
                
                  <FormGroup>
                    <Col sm={12}>
                      <Label for="description">Description</Label>
                      <Input type="text" name="description" required  id="description" defaultValue={questionCategory?.description} />
                    </Col>
                  </FormGroup>
                  
                
                  <FormGroup>
                    <Col sm={12}>
                  <Button className="btn btn-primary mr-2">Submit</Button>
                  </Col>
                    </FormGroup>
                </Form>
              </Card>
            </div>
          </Row>
         
        </Container>
      </>
        )
    }
 export default EditCategoryQuestion;

