import axiosInstance from '../../../axiosInstance';
import { GET_RESOURCE_CATEGORIES, CREATE_RESOURCE_CATEGORY, UPDATE_RESOURCE_CATEGORY, DELETE_RESOURCE_CATEGORY, CREATE_RESOURCE_GROUP, GET_RESOURCE_GROUP } from '../../types';

// React Notification
import { NotificationManager } from 'react-notifications';

export const getResourcesCategories = () => async dispatch =>{

  const res = await axiosInstance.get('/resources/categories/list');
  dispatch ({ 
      type : GET_RESOURCE_CATEGORIES,
      payload : res.data.data
  }); 
}


export const addResourceCategory = (category) => async dispatch => {
    const res = await axiosInstance.post('/resources/categories/create', category);
    dispatch ({
        type: CREATE_RESOURCE_CATEGORY,
        payload: res.data.data
    });
} 

// Update Resource category Action
export const updateResourceCat = category => async dispatch => {
  
  try{
    const res = await axiosInstance.put(`/resource-categories/${category.get("id")}`, category);
    dispatch ({
        type: UPDATE_RESOURCE_CATEGORY,
        payload: res.data
    });
    NotificationManager.success('Resource Category updated successfully !','Success!', 2000);
      window.setTimeout(function(){window.location.reload()}, 700);
  }
  catch(error){
    alert(error?.response?.data?.error.message ?? error.message)
  }
}

// Delete Resource Category
export const deleteResourceCat = (id) => async dispatch => {
  
  try{
    await axiosInstance.delete(`/resource-categories/${id}`);
    dispatch({
        type: DELETE_RESOURCE_CATEGORY,
        payload: id
    })
    NotificationManager.success('Resource Category deleted successfully !','Success!', 2000);
      window.setTimeout(function(){window.location.reload()}, 700);
  }
  catch(error){
    alert(error?.response?.data?.error.message ?? error.message)
  }
}


export const getResourcesGroups = () => async dispatch =>{

  const res = await axiosInstance.get('/resources/groups/list');
  dispatch ({ 
      type : GET_RESOURCE_GROUP,
      payload : res.data.data
  }); 
}


export const addResourceGroup = (category) => async dispatch => {
    const res = await axiosInstance.post('/resources/groups/create', category);
    dispatch ({
        type: CREATE_RESOURCE_GROUP,
        payload: res.data.data
    });
} 

// // Update Resource category Action
// export const updateResourceCat = category => async dispatch => {
  
//   try{
//     const res = await axiosInstance.put(`/resource-categories/${category.get("id")}`, category);
//     dispatch ({
//         type: UPDATE_RESOURCE_CATEGORY,
//         payload: res.data
//     });
//     NotificationManager.success('Resource Category updated successfully !','Success!', 2000);
//       window.setTimeout(function(){window.location.reload()}, 700);
//   }
//   catch(error){
//     alert(error?.response?.data?.error.message ?? error.message)
//   }
// }

// // Delete Resource Category
// export const deleteResourceCat = (id) => async dispatch => {
  
//   try{
//     await axiosInstance.delete(`/resource-categories/${id}`);
//     dispatch({
//         type: DELETE_RESOURCE_CATEGORY,
//         payload: id
//     })
//     NotificationManager.success('Resource Category deleted successfully !','Success!', 2000);
//       window.setTimeout(function(){window.location.reload()}, 700);
//   }
//   catch(error){
//     alert(error?.response?.data?.error.message ?? error.message)
//   }
// }
