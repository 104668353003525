import React, { Component } from 'react';
import moment from 'moment';
import ArticleService from './ArticleService';
import { Link } from 'react-router-dom';
// React Notification
import { NotificationManager } from 'react-notifications';
import axiosInstance from '../../../redux/axiosInstance';
import Pagination from 'react-js-pagination';

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Button
} from 'reactstrap';

// core components
import Header from 'components/Headers/Header.js';

export default class ListArticles extends Component {
  constructor() {
    super();
    this.state = {
      articles: [],
      activePage: 1,
      itemsCountPerPage: 1,
      totalItemsCount: 1,
      pageRangeDisplayed: 3
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.deleteArticle = this.deleteArticle.bind(this);
  }

  deleteArticle(id) {
    ArticleService.deleteArticle(id).then((res) => {
      this.setState({
        articles: this.state.articles.filter((article) => article.id !== id)
      });
      NotificationManager.success(
        'Article deleted successfully !',
        'Success!',
        2000
      );
      window.setTimeout(function () {
        window.location.reload();
      }, 700);
    });
  }

  approveArticle(id) {
    ArticleService.approveArticle(id).then((res) => {
      NotificationManager.success(
        'Article approved successfully !',
        'Success!',
        2000
      );
      this.setState({
        articles: this.state.articles.map((article) => {
          if(article._id === id) {
            article.status = 'approved';
            return article
          } else {
            return article;
          }
        })
      })
    });
  }

  rejectArticle(id) {
    ArticleService.rejectArticle(id).then((res) => {
      NotificationManager.success(
        'Article rejected successfully !',
        'Success!',
        2000
      );
      this.setState({
        articles: this.state.articles.map((article) => {
          if(article._id === id) {
            article.status = 'rejected';
            return article
          } else {
            return article;
          }
        })
      })
    });
  }

  componentDidMount() {
    axiosInstance.get('/articles/admin/list').then((response) => {
      axiosInstance.get('/articles/admin/categories/list').then((res) => {
        axiosInstance.get('/articles/list/agent/articles').then((unapprovedArticleRes) => {
          this.setState({
            articles: response.data.data.filter(ob => ob.status !== "pending"),
            itemsCountPerPage: response.data.perPage,
            totalItemsCount: response.data.totalData,
            activePage: response.data.currentPage,
            categories: res?.data?.data,
            unapprovedArticles: unapprovedArticleRes.data.data
          });
        })
      });
    });

  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    axiosInstance.get('/articles/admin/list?page=' + pageNumber).then((response) => {
      this.setState({
        articles: response.data.data,
        itemsCountPerPage: response.data.data.perPage,
        totalItemsCount: response.data.data.totalData,
        activePage: response.data.data.currentPage
      });
    });
  }

  handleDeleteArticles(slug) {
    ArticleService.deleteArticle(slug).then((res) => {
      if (res.status === 200) {
        NotificationManager.success(
          'Article has been successfully deleted !',
          'Success!',
          2000
        );
        window.location.reload()
      }
    })
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Link to="/admin/article/create">
                    <Button color="info">Add New Article +</Button>
                  </Link>
                  <Link
                    style={{ float: 'right' }}
                    to="/admin/article/categories"
                  >
                    <Button color="info">Article Categories</Button>
                  </Link>
                  &nbsp; &nbsp;
                  <Button color="info" onClick={() => {
                    this.setState({
                      isUnApprovedView: !this.state.isUnApprovedView
                    })
                  }}> {this.state.isUnApprovedView ? 'Approved Articles' : 'Un approved articles'} </Button>
                </CardHeader>
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    News &amp; Articles
                    {/* <FormGroup style={{float: 'right'}}>
                      <input type="text"  className="form-control" onChange={ (e) => this.handleSearchChange(e) } placeholder="Search here"/>
                    </FormGroup> */}
                  </h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Title</th>
                      {/* <th scope="col">Body</th> */}
                      <th scope="col">Category</th>
                      <th scope="col">Posted By</th>
                      <th scope="col">Status</th>
                      {/* <th scope="col">Category</th> */}
                      <th scope="col">Date Created</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {!this.state.isUnApprovedView && this.state.articles.map((article) => {
                      return (
                        <tr key={article.id}>
                          <td style={{textTransform: 'capitalize'}}>{article.title}</td>
                          {/* <td>{article.body}</td> */}
                          <td>{this.state.categories?.find(ob => ob._id === article.category_id)?.title}</td>
                          <td>{article?.agent_id ?? 'Admin'}</td>
                          <td>{article.status}</td>
                          <td>
                            {moment(article.createdAt).format('MMM-DD-YYYY')}
                          </td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <Link
                                    to={`/admin/article/edit/${article.slug}`}
                                  >
                                    <DropdownItem>Edit</DropdownItem>
                                  </Link>

                                {article.status === 'rejected' ||
                                article.status === 'pending' ? (
                                  <div>
                                    <DropdownItem
                                      href="#!"
                                      onClick={() =>
                                        this.approveArticle(article.slug)
                                      }
                                    >
                                      Approve
                                    </DropdownItem>
                                  </div>
                                ) : (
                                  <DropdownItem
                                    href="#!"
                                    onClick={() =>
                                      this.rejectArticle(article.slug)
                                    }
                                  >
                                    Reject
                                  </DropdownItem>
                                )}
                                <DropdownItem href="#!"
                                              onClick={() => this.handleDeleteArticles(article.slug)}
                                >Delete</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}

                      {this.state.isUnApprovedView && this.state.unapprovedArticles.map((article) => {
                      return (
                        <tr key={article.id}>
                          <td  style={{textTransform: 'capitalize'}}>{article.title}</td>
                          {/* <td>{article.body}</td> */}
                          <td>{this.state.categories?.find(ob => ob._id === article.category_id)?.title}</td>
                          <td>{article?.agent_id ?? 'Admin'}</td>
                          <td>{article.status}</td>
                          <td>
                            {moment(article.createdAt).format('MMM-DD-YYYY')}
                          </td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <Link
                                    to={`/admin/article/edit/${article.slug}`}
                                  >
                                    <DropdownItem>Edit</DropdownItem>
                                  </Link>

                                {article.status === 'rejected' ||
                                article.status === 'pending' ? (
                                  <div>
                                    <DropdownItem
                                      href="#!"
                                      onClick={() =>
                                        this.approveArticle(article.slug)
                                      }
                                    >
                                      Approve
                                    </DropdownItem>
                                  </div>
                                ) : (
                                  <DropdownItem
                                    href="#!"
                                    onClick={() =>
                                      this.rejectArticle(article.slug)
                                    }
                                  >
                                    Reject
                                  </DropdownItem>
                                )}
                                <DropdownItem href="#!"
                                              onClick={() => this.handleDeleteArticles(article.slug)}
                                >Delete</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
